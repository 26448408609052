<template>
  <div class="document-input" :class="{'small': small, 'input-filled': filled}">
    <v-bottom-sheet
        right
        app
        v-model="navigation"
        disable-route-watcher
        persistent
        bottom
        width="80%"
        v-if="selectButtonObject.show"
    >
      <list_wrapper
          :dialog="navigation"
          :list_name="selectButtonObject.name"
          :list_group_name="selectButtonObject.group"
          :hierarchy="selectButtonObject.hierarchy"
          :only_groups="selectButtonObject.only_groups"
          :selectable="true"
          :title="selectButtonObject.title"
          :element_title="selectButtonObject.element_title"
          :modal_wrapper="true"
          :action_props="selectButtonObject.action_props"
          :sync_action_props="selectButtonObject.sync_action_props"
          :action_filter="selectButtonObject.action_filter"
          :owner_id="(selectButtonObject.action_props || {}).owner_id || 0"
          :document="selectButtonObject.document"
          :full_screen="selectButtonObject.full_screen"
          :show-additional-field="selectButtonObject.showAdditionalField || false"
          :alternative_header_name="selectButtonObject.alternative_header_name"
          @onReload="onWrapperReload"
          @closeDialog="closeListDialog"
          @selectItem="selectedRow"
      />
    </v-bottom-sheet>
    <div class="label" v-if="label && !hideLabel"
         :class="{'label-error': !inputValue && required, 'label-middle': !inputValue && !focused}"
         :style="label_style"
    >
      {{ label }}
    </div>
    <div style="position:relative;" ref="select_wrapper" class="not-label">
      <input
          type="text"
          v-model="inputValue"
          @focusin="onFocus"
          @input="onInput"
          class="included"
          :readonly="preventInput"
          :class="{'error-input': !inputValue && required}"
          :style="`font-size: ${small ? 0.79 : fontSize}rem;
                   min-height: ${minHeight}px;
                   ${transparent ? 'background-color: transparent !important;' : ''}
                   ${selectButtonObject.show && !disabled ? 'padding-right: 65px' : ''}
                   ${!selectButtonObject.show && !disabled ? 'padding-right: 35px' : ''}`
                 "
          v-click-outside="{
                            handler: onClose,
                            include: included
                          }"
          :disabled="disabled"
      >
      <div class="select-wrapper elevation-5" v-if="showSelect" :style="`max-width: ${width||400}px`">
        <template v-if="showLabel">
          <v-list dense class="included">
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="28" color="success">
                  mdi-alert-circle-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="font-weight-medium" style="font-size: .9rem; line-height: 1.05rem">
                Почніть вводити текст для пошуку
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <template v-else>
          <v-list dense class="included py-0" v-if="filteredData.length">
            <v-list-item
                v-for="(elem, index) in filteredData"
                :key="index"
                @click.stop="selectEvent(elem)"
                class="pl-2 pr-8 text-break"
                style="border-bottom: 1px solid #d4d4d4; border-radius: 0">
              <v-list-item-content v-html="highlightSearch(elem[itemText])" class="list-content"/>
            </v-list-item>
          </v-list>
          <v-list dense class="included" v-else>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="28" color="error lighten-1">
                  mdi-alert
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="font-weight-medium" style="font-size: .9rem">
                Жодного запису не знайдено
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </div>
      <div
          style="width: 57px; position: absolute; right: 0; top: 4px; bottom: 1px;"
          :style="`${transparent ? 'background-color: transparent !important;' : 'background-color: rgb(245 245 245);'}`"
          v-if="!disabled" class="select-input-buttons"
      >
        <div :class="clearable && selectButtonObject.clearable && !disabled ? 'input-select-icon-left' : ' input-select-icon'"
             v-if="selectButtonObject.show && !disabled">
          <v-icon size="20" :color="!inputValue && required ? 'error lighten-1' : 'success'"
                  @click="openList">
            mdi-dots-horizontal
          </v-icon>
        </div>

        <div class="input-clear-icon" v-if="clearable && !selectButtonObject.show && !disabled">
          <v-icon size="17" @click="clearSelected">mdi-window-close</v-icon>
        </div>

        <div class="input-clear-icon" v-if="clearable && selectButtonObject.clearable && !disabled">
          <v-icon size="17" @click="clearSelected">mdi-window-close</v-icon>
        </div>

        <div class="input-icon">
          <v-icon size="20">{{ showSelect ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getActionFilter} from "@/utils/accounting";

export default {
  name: "document_select_input",
  props: {
    owner_id: {
      type: Number,
      default: null
    },
    field: {
      type: String,
      default() {
        return ''
      }
    },
    row_num: {
      type: Number,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemSearch: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    label: {
      type: String,
      default: ''
    },
    label_style: {
      type: String,
      default: ''
    },
    nudgeWidth: {
      type: String,
      default: '400px'
    },
    required: {
      type: Boolean,
      default: true
    },
    showOnFocus: {
      type: Boolean,
      default: true
    },
    showSearchOnFocus: {
      type: Boolean,
      default: true
    },
    computedGetter: {
      type: String,
      default: ''
    },
    computedAction: {
      type: String,
      default: ''
    },
    importGetter: {
      type: Object,
      default() {
        return {}
      }
    },
    syncImportModule: {
      type: Boolean,
      default() {
        return false
      }
    },
    importModule: {
      type: String,
      default() {
        return 'icons';
      }
    },
    inputValueAsValue: {
      type: Boolean,
      default: true
    },
    fontSize: {
      type: Number,
      default() {
        return 0.89;
      }
    },
    minHeight: {
      type: Number,
      default() {
        return 28;
      }
    },
    showSelectOnFocus: {
      type: Boolean,
      default: true
    },
    selectButtonObject: {
      type: Object,
      default() {
        return {
          show: false,
          name: '',
          group: '',
          hierarchy: false,
          title: '',
          element_title: '',
          only_groups: false,
          action_props: {},
          action_filter: '',
          document: false,
          full_screen: false,
          sync_action_props: false,
          clearable: false,
          showAdditionalField: false,
          alternative_header_name: ''
        }
      }
    },
    actionProps: {
      type: Object,
      default() {
        return {}
      }
    },
    actionFilter: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    preventInput: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false
    },
    syncActionProps: {
      type: Boolean,
      default: false
    },
    showAdditionalField: {
      type: Boolean,
      default: false
    }
  },
  components: {
    list_wrapper: () => import("@/components/accounting/list_modal/list_wrapper")
  },
  computed: {
    filteredData() {
      const input_text = `${this.inputValue}` || ''

      if (this.importGetter.name || this.showOnFocus) {
        return this.entries
      }

      if (input_text && this.showSelect && !this.showLabel && this.showOnFocus && !this.showSearchOnFocus) {
        return this.entries || []
      }

      if (input_text === `${this.value}`) return (this.entries || []).slice(0, 300)


      if (this.inputValue) {
        return (this.entries || []).filter(item => {
          return item[this.itemSearch].toLowerCase().indexOf(input_text.toLowerCase()) > -1
        }).slice(0, 300)
      } else {
        return (this.entries || []).slice(0, 300)
      }
    },
    actionPropsForFetch: {
      get: function () {
        return Object.assign({}, this.actionProps, this.selectButtonObject.action_props)
      }
    }
  },
  data() {
    return {
      width: 0,
      entries: [],
      showSelect: false,
      showLabel: false,
      inputValue: this.value,
      selected: null,
      watcher: null,
      navigation: false,
      focused: false,
      watcher_filter: null,
      watcher_owner: null,
      watcher_action_props: null,
      watcher_action_props_: null,
      watcher_import_getter: null,
      watcher_computed_action: null
    }
  },
  methods: {
    getNudgeWidth() {
      const wrapper = this.$refs.select_wrapper
      let width = 400
      if (wrapper) {
        width = wrapper.clientWidth
      }
      if (width <= 250) {
        width = 400
      }
      this.width = width
    },
    getActionFilter,
    openList() {
      this.navigation = true
    },
    closeListDialog() {
      this.navigation = false
    },
    selectedRow(payload) {
      let emit_payload = {}
      let value_ = this.value
      if (payload === undefined) {
        this.selected = null
        emit_payload = Object.assign(emit_payload, {field: this.field})
      } else {
        this.selected = Object.assign({field: this.field}, payload)
        emit_payload = Object.assign({}, {row_num: this.row_num, ...payload})
      }
      const input_value = emit_payload.name || emit_payload.full_name || emit_payload.short_name
          || emit_payload.representation || ''
      if (value_ !== emit_payload.id) {
        this.inputValue = this.inputValueAsValue ? emit_payload.id : input_value
      }
      this.$emit('selectChange', emit_payload)
      this.$emit('input', emit_payload.id)
      this.$emit('inputValue', {field: this.field, text: this.inputValue, name: 'selectedRow'})
      this.selected = null
      this.showSelect = false
    },
    onFocus() {
      this.focused = true
      if (!this.showSelectOnFocus) return

      this.showSelect = true

      if (this.showSelectOnFocus || this.showOnFocus) {
        this.showLabel = false
      } else {
        this.showLabel = `${this.value}` === `${this.inputValue}`
      }

      this.getNudgeWidth()
    },
    onInput() {
      this.showSelect = true
      this.showLabel = false
      this.getNudgeWidth()
    },
    selectEvent(item) {
      this.inputValue = this.inputValueAsValue ? item[this.itemValue] : item[this.itemText]
      this.selected = item
      this.$emit('selectChange', Object.assign({}, {row_num: this.row_num, ...item, field: this.field}))
      this.$emit('input', item[this.itemValue])
      this.$emit('inputValue', {field: this.field, text: this.inputValue, name: 'selectEvent'})
      this.selected = null
      this.showSelect = false
    },
    clearSelected() {
      this.inputValue = null
      this.selected = null
      this.$emit('selectChange', Object.assign({},
          {row_num: this.row_num, value: null, id: null, field: this.field}))
      this.$emit('input', '')
      this.$emit('inputValue', {field: this.field, text: '', name: 'selectEvent'})
      this.showSelect = false
    },
    onClose() {
      this.focused = false
      this.showSelect = false
      this.showLabel = false
      if (!this.selected) {
        const value = `${this.value}`
        this.inputValue = value !== 'null' && value !== 'undefined' && value !== 'NaN' ? this.inputValue : ''

        if (this.value) {
          const value_item = this.entries.find(i => i[this.itemValue] === this.value)
          if (value_item) {
            const inputValue = this.inputValueAsValue ? value_item[this.itemValue] : value_item[this.itemText]

            if (this.inputValue !== inputValue) {
              this.inputValue = inputValue
            }
          }
        }
      }
    },
    highlightSearch(text) {
      if (!text) return ''
      if (this.showSelect && !this.showLabel) {
        return text.replace(new RegExp(this.inputValue, "g"), "<strong>" + this.inputValue + "</strong>");
      } else {
        return text
      }
    },
    included() {
      return [document.querySelector('.included')]
    },
    watch_action_props_() {
      this.watcher_action_props_ = this.$watch(
          'actionProps',
          {
            immediate: false,
            deep: false,
            handler(payload, payload_old) {
              const payload_n = payload || {}
              const payload_o = payload_old || {}

              if (JSON.stringify(payload_n) === JSON.stringify(payload_o)) return;

              if (this.computedGetter) {
                let entries = []

                const find_position = this.computedGetter.indexOf('_list_select') || 0
                const loading_getter = (this.computedGetter || '').substring(0, find_position) + '_loaded'
                const loading_state = loading_getter.replace('_loaded', '_loading').replace('get_', '')

                const action_filter = this.getActionFilter(this.actionFilter)

                this.$store.commit("SET_ACCOUNTING_DATA_LOADING", {state: loading_state, value: true})
                this.$store.dispatch(this.computedAction, payload)
                    .then(() => {
                      entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
                      if (action_filter) {
                        entries = (entries || []).filter(action_filter)
                      }
                      this.entries = entries

                      const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
                      if (!inputValue) {
                        this.inputValue = null

                        this.$emit('selectChange', Object.assign({}, {row_num: this.row_num}))
                        this.$emit('input', '')
                        this.$emit('inputValue', {field: this.field, text: this.inputValue, name: 'selectEvent'})
                      }
                    })
              }
            }
          })

    },
    watch_action_props() {
      this.watcher_action_props = this.$watch(
          'actionPropsForFetch',
          {
            immediate: false,
            deep: false,
            handler(payload, payload_old) {
              if (!payload && !payload_old) return

              const payload_n = payload || {}
              const payload_o = payload_old || {}

              if (JSON.stringify(payload_n) === JSON.stringify(payload_o)) return;

              if (this.computedGetter) {
                let entries = []

                const find_position = this.computedGetter.indexOf('_list_select') || 0
                const loading_getter = (this.computedGetter || '').substring(0, find_position) + '_loaded'
                const loading_state = loading_getter.replace('_loaded', '_loading').replace('get_', '')

                const action_filter = this.getActionFilter(this.actionFilter)

                this.$store.commit("SET_ACCOUNTING_DATA_LOADING", {state: loading_state, value: true})
                this.$store.dispatch(this.computedAction, payload)
                    .then(() => {
                      entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
                      if (action_filter) {
                        entries = (entries || []).filter(action_filter)
                      }
                      this.entries = entries

                      const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
                      if (!inputValue) {
                        this.inputValue = null

                        this.$emit('selectChange', Object.assign({}, {row_num: this.row_num}))
                        this.$emit('input', '')
                        this.$emit('inputValue', {field: this.field, text: this.inputValue, name: 'selectEvent'})
                      } else {
                        this.inputValue = inputValue[this.itemText]
                      }
                    })
              }
            }
          }
      )
    },
    watch_value() {
      this.watcher = this.$watch(
          'value',
          {
            immediate: false,
            deep: false,
            handler(payload) {
              if (!payload) {
                this.inputValue = ''
              } else {
                if (payload) {
                  const inputValue = this.entries.find(item => item[this.itemValue] === payload)
                  if (inputValue) {
                    this.inputValue = inputValue[this.itemText]
                  }
                }
              }
              this.$emit('inputValue', {field: this.field, text: this.inputValue, name: 'watch_value'})
            }
          }
      )
    },
    watch_filter() {
      this.watcher_filter = this.$watch(
          'actionFilter',
          {
            immediate: false,
            deep: false,
            handler(payload) {
              let entries = []
              const action_filter = this.getActionFilter(payload)

              entries = this.$store.getters[this.computedGetter]

              if (action_filter) {
                entries = (entries || []).filter(action_filter)
              }

              this.entries = entries

              if (this.value) {
                const f = this.entries.find(i => i[this.itemValue] === this.value)
                if (!f) {
                  this.inputValue = ''
                  this.$emit('selectChange', Object.assign({}, {row_num: this.row_num}))
                  this.$emit('input', null)
                } else {
                  this.inputValue = f[this.itemText]
                }
              }
              this.$emit('inputValue', {field: this.field, text: this.inputValue, name: 'watch_filter'})
            }
          }
      )
    },
    watch_import_getter() {
      this.watcher_import_getter = this.$watch(
          'importGetter',
          {
            immediate: false,
            deep: false,
            handler(new_value, old_value) {
              if (JSON.stringify(new_value) !== JSON.stringify(old_value)) {
                if (this.importModule === 'icons') {
                  import('/src/utils/icons')
                      .then(module => {
                        this.entries = module[this.importGetter.name]
                        const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
                        if (inputValue) {
                          this.inputValue = inputValue[this.itemText]
                        }
                        if (this.inputValueAsValue) {
                          this.inputValue = this.value
                        }
                      })
                } else {
                  import('/src/utils/accounting').then(module => {
                    this.entries = module[this.importGetter.name]
                    const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
                    if (inputValue) {
                      this.inputValue = inputValue[this.itemText]
                    }
                    if (this.inputValueAsValue) {
                      this.inputValue = this.value
                    }
                  })
                }
              }
            }
          }
      )
    },
    watch_computed_action() {
      this.watcher_computed_action = this.$watch(
          'computedAction',
          {
            immediate: false,
            deep: false,
            handler(new_value, old_value) {
              if (new_value !== old_value) {
                this.onComputedActionChange(new_value)
              }
            }
          }
      )
    },
    onComputedActionChange(computedAction) {
      if (this.computedGetter) {
        let entries = []
        let comp_data = this.$store.getters[this.computedGetter]

        const find_position = this.computedGetter.indexOf('_list_select') || 0
        const loading_getter = (this.computedGetter || '').substring(0, find_position) + '_loaded'
        const loading_state = loading_getter.replace('_loaded', '_loading').replace('get_', '')
        let data_loaded = loading_getter === '_loaded' ? false : this.$store.getters[loading_getter]
        data_loaded = this.syncActionProps ? false : data_loaded

        const action_filter = this.getActionFilter(this.actionFilter)

        if (!data_loaded || !comp_data.length) {
          const add_action_props = this.owner_id ? {owner_id: this.owner_id} : {}
          this.$store.commit("SET_ACCOUNTING_DATA_LOADING", {state: loading_state, value: true})
          this.$store.dispatch(computedAction, Object.assign({}, this.actionPropsForFetch, add_action_props))
              .then(() => {
                entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
                if (action_filter) {
                  entries = (entries || []).filter(action_filter)
                }
                this.entries = entries

                const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
                if (inputValue) {
                  this.inputValue = inputValue[this.itemText]
                }
                this.inputValue = this.value
                this.$emit('inputValue', {field: this.field, text: this.inputValue, name: 'watch_value'})
              })
        } else {
          entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
          if (action_filter) {
            entries = (entries || []).filter(action_filter)
          }
          this.entries = entries

          const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
          if (inputValue) {
            this.inputValue = inputValue[this.itemText]
          }
          this.inputValue = this.value
          this.$emit('inputValue', {field: this.field, text: this.inputValue, name: 'watch_value'})
        }
      }
    },
    onWrapperReload(payload) {
      if (payload) {
        if (this.computedGetter) {
          const action_filter = this.getActionFilter(this.actionFilter)
          let entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
          if (action_filter) {
            entries = (entries || []).filter(action_filter)
          }
          this.entries = entries
        }
      }
    }
  },
  created() {
    // this.watch_action_props_()
    this.watch_computed_action()
    if (this.syncActionProps) {
      this.watch_action_props()
    }
    if (this.syncImportModule && !this.row_num) {
      this.watch_import_getter()
    }
    if (this.computedGetter) {
      let entries = []
      let comp_data = this.$store.getters[this.computedGetter]

      const find_position = this.computedGetter.indexOf('_list_select') || 0
      const loading_getter = (this.computedGetter || '').substring(0, find_position) + '_loaded'
      const loading_state = loading_getter.replace('_loaded', '_loading').replace('get_', '')
      let data_loaded = loading_getter === '_loaded' ? false : this.$store.getters[loading_getter]
      data_loaded = this.syncActionProps ? false : data_loaded

      const action_filter = this.getActionFilter(this.actionFilter)

      if (!data_loaded || !comp_data.length) {
        const add_action_props = this.owner_id ? {owner_id: this.owner_id} : {}
        this.$store.commit("SET_ACCOUNTING_DATA_LOADING", {state: loading_state, value: true})

        this.$store.dispatch(this.computedAction, Object.assign({}, this.actionPropsForFetch, add_action_props ))
            .then(() => {
              entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
              if (action_filter) {
                entries = (entries || []).filter(action_filter)
              }
              this.entries = entries

              const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
              if (inputValue) {
                this.inputValue = inputValue[this.itemText]
              }
              if (!this.inputValueAsValue) {
                this.watch_value()
              } else {
                this.inputValue = this.value
              }
              this.watch_filter()
            })
      } else {
        entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
        if (action_filter) {
          entries = (entries || []).filter(action_filter)
        }
        this.entries = entries

        const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
        if (inputValue) {
          this.inputValue = inputValue[this.itemText]
        }
        if (!this.inputValueAsValue) {
          this.watch_value()
        } else {
          this.inputValue = this.value
        }
        this.watch_filter()
      }
    } else {
      if (this.importModule === 'icons') {
        import('/src/utils/icons')
            .then(module => {
              this.entries = module[this.importGetter.name]
              const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
              if (inputValue) {
                this.inputValue = inputValue[this.itemText]
              }
              if (!this.inputValueAsValue) {
                this.watch_value()
              } else {
                this.inputValue = this.value
              }
              this.watch_filter()
            })
      } else {
        import('/src/utils/accounting').then(module => {
          this.entries = module[this.importGetter.name]
          const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
          if (inputValue) {
            this.inputValue = inputValue[this.itemText]
          }
          if (!this.inputValueAsValue) {
            this.watch_value()
          } else {
            this.inputValue = this.value
          }
          this.watch_filter()
        })
      }
    }
  },
  beforeDestroy() {
    if (this.watcher) {
      this.watcher()
    }
    if (this.watcher_filter) {
      this.watcher_filter()
    }
    if (this.watcher_action_props) {
      this.watcher_action_props()
    }
    if (this.watcher_action_props_) {
      this.watcher_action_props_()
    }
    if (this.watcher_computed_action) {
      this.watch_computed_action()
    }
  }
}
</script>

<style scoped lang="scss">
.select-wrapper {
  position: fixed;
  background: white;
  z-index: 9999;
  width: max-content;
  display: flex;
  flex: 1;
  overflow: overlay;
  max-height: 400px;

  &:deep(.v-list-item--link) {
    min-height: 30px !important;
  }
}

.list-content {
  display: block !important;
  font-size: .83rem;
  padding: 4px 0 !important;

  &:deep(strong) {
    color: #4caf50;
    font-weight: 600;
  }
}
</style>